import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ArkivetLogo } from '../components/ArkivetLogo';
import { ArrowIcon } from '../components/icons/ArrowIcon';
import { HeartIcon } from '../components/icons/HeartIcon';
import { Loader } from '../components/Loader';
import { FurnitureModal } from '../components/modal/FurnitureModal';
import { Modal } from '../components/modal/Modal';
import { MoodboardModal } from '../components/modal/MoodboardModal';
import {
  FurnitureType,
  RoomFurnitureType,
  useProject,
  useUpdateCustomerLike,
} from '../services/api/useSanity';
import { formatDate } from '../utils/date';
import { numberWithCommas } from '../utils/number';

export const Project: FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { data, isLoading } = useProject(slug);
  if (isLoading) {
    return <Loader size={100} />;
  }

  if (!data?._updatedAt) {
    return <>Ingen data og vise</>;
  }
  const furniturePrices = data.rooms_with_furniture
    .filter((room) => !!room.room_furniture)
    .map(({ room_furniture }) => ({
      prices: room_furniture
        .filter((furniture) => furniture.customerLike)
        .map((d) => d.price * (d.count || 1)),
    }))
    .map((d) => d.prices)
    .flat();

  const totalLikedFurniturePrice = furniturePrices.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );

  const formatCurrency = (number: number) =>
    Intl.NumberFormat('no-no', {
      notation: 'standard',
      style: 'currency',
      currency: 'NOK',
    }).format(number);

  return (
    <StyledProjectWrapper>
      <ArkivetLogo />
      <div
        style={{
          marginBottom: 20,
        }}
      />
      <div className='header'>
        <h2>{data.project_name}</h2>
        <p>
          Budsjett:
          <b style={{ marginLeft: 5 }}>
            {data.budget ? formatCurrency(data.budget) : 0}
          </b>
        </p>
      </div>
      <p className='last-updated'>
        Sist oppdatert: {formatDate(data._updatedAt)}
      </p>
      {data?.rooms_with_furniture.map((rwf, index) => (
        <Room
          key={rwf.room}
          data={rwf}
          projectId={data._id}
          roomIndex={index}
        />
      ))}
      <p className='total-price'>
        Total pris av det du liker:{' '}
        <b>{formatCurrency(totalLikedFurniturePrice)}</b>
      </p>
    </StyledProjectWrapper>
  );
};

const Room: FC<{
  data: RoomFurnitureType;
  projectId: string;
  roomIndex: number;
}> = ({ data, projectId, roomIndex }) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [isMoodboardModalOpen, setIsMoodboardModalOpen] =
    useState<boolean>(false);
  return (
    <StyledRoom>
      <div onClick={() => setExpanded(!expanded)} className='header'>
        <h2>{data.room}</h2>
        <ArrowIcon rotation={expanded ? 0 : 180} />
      </div>
      {expanded && (
        <div>
          {!!data.moodboard_images && (
            <h2
              onClick={() => setIsMoodboardModalOpen(!isMoodboardModalOpen)}
              className='moodboard-link'
            >
              Moodboards
            </h2>
          )}
          {!!data.room_furniture?.length ? (
            <>
              {data.room_furniture.map((furniture, index) => (
                <Furniture
                  key={index}
                  furniture={furniture}
                  roomIndex={roomIndex}
                  projectId={projectId}
                  index={index}
                />
              ))}
            </>
          ) : (
            <p>Ingen møbler her</p>
          )}
        </div>
      )}
      {isMoodboardModalOpen && (
        <Modal
          closeModal={() => setIsMoodboardModalOpen(!isMoodboardModalOpen)}
        >
          <MoodboardModal imageUrls={data.moodboard_images} />
        </Modal>
      )}
    </StyledRoom>
  );
};

const Furniture: FC<{
  furniture: FurnitureType;
  roomIndex: number;
  projectId: string;
  index: number;
}> = ({ furniture, roomIndex, projectId, index }) => {
  const updateCustomerLike = useUpdateCustomerLike();
  const handleUpdateLike = (value: boolean) => {
    updateCustomerLike.mutate({
      value,
      roomIndex,
      furnitureIndex: index,
      projectId,
    });
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <StyledFurniture key={furniture.model_name}>
      <img
        onClick={() => setIsModalOpen(true)}
        src={furniture.imageUrl}
        alt={furniture.model_name}
      />
      <div onClick={() => setIsModalOpen(true)} className='description'>
        <div>
          <div>
            <b>{furniture.model_name}</b>
            <span>, x {furniture.count}</span>
          </div>
          <p>{furniture.provider.title}</p>
        </div>
        <p>{furniture.description}</p>
        <b>{`Pris: ${numberWithCommas(furniture.price)} kr`}</b>
      </div>
      {updateCustomerLike.isLoading ? (
        <Loader />
      ) : (
        <HeartIcon
          filled={furniture.customerLike}
          onClick={() => {
            handleUpdateLike(!furniture.customerLike);
          }}
        />
      )}
      {isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <FurnitureModal
            isLoadingLike={updateCustomerLike.isLoading}
            heartClick={() => handleUpdateLike(!furniture.customerLike)}
            furniture={furniture}
          />
        </Modal>
      )}
    </StyledFurniture>
  );
};

const StyledProjectWrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
  }
  .last-updated {
    font-size: 12px;
  }
  .total-price {
    display: flex;
    justify-content: end;
    b {
      margin: 0 5px;
    }
  }
`;

const StyledRoom = styled.div`
  margin-bottom: 20px;
  .header {
    border-radius: 10px;
    background-color: #a5dcfb40;
    padding: 0 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }
  .moodboard-link {
    font-weight: 359;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.color.interactive};
  }
`;

const StyledFurniture = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 40px;
  align-items: center;
  height: 180px;
  img {
    width: 100%;
    aspect-ratio: 1;
  }
  .description {
    padding: 10px;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      b {
        margin: auto 0;
      }
      p {
        color: #899598;
        font-size: 12px;
      }
    }
    margin-left: 10px;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 3;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    b {
      align-self: flex-end;
    }
  }
`;

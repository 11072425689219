import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { Project } from './pages/Project';
import { GlobalStyle } from './styles/GlobalStyle';
import { ArkivetThemeProvider, useTheme } from './theme/useTheme';

const queryClient = new QueryClient();

export const App = () => {
  return (
    <ArkivetThemeProvider>
      <QueryClientProvider client={queryClient}>
        <InnerApp />
      </QueryClientProvider>
    </ArkivetThemeProvider>
  );
};

const InnerApp = () => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StyledAppContainer>
        <Routes>
          <Route element={<Project />} path='/:slug' />
        </Routes>
      </StyledAppContainer>
    </ThemeProvider>
  );
};

const StyledAppContainer = styled.div`
  width: 75vw;
  margin: 20px auto 0 auto;

  @media screen and (max-width: 800px) {
    width: 90vw;
  }
`;

import sanityClient from '@sanity/client';

// TODO: Revoke token and have these values as build-time secrets
export const client = sanityClient({
  projectId: 'irxt4u2r',
  dataset: process.env.NODE_ENV === 'development' ? 'test' : 'production',
  useCdn: false,
  ignoreBrowserTokenWarning: true,
  apiVersion: '2022-06-23',
  token:
    'skJkB1TtnyBBarxqfHJZe0L98zcWYzYCbIvTzyez0aeyzc6iyze0rlQFAykh6L6WeYexDA2qm5nmo7sPKDrCXF5BDTcoC5bsYPAvj35DXEvUQXdkFhlOHPBetX7IEzDLPYOkazL9HukTp7EqbP6OG6FKzmJT9uUyogkqb2ZNEhJoW5ExtV11',
});
